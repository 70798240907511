const SET_COLLECTION_REF = 'SET_COLLECTION_REF'
const SET_STORY_REF = 'SET_STORY_REF'
const SET_WHEREBUY_REF = 'SET_WHEREBUY_REF'

let initialState = {
    collectionRef: null,
    storyRef: null,
    whereBuyRef: null
}

const refReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_COLLECTION_REF:
            return {
                ...state,
                collectionRef: action.collectionRef
            }
        case SET_STORY_REF:
            return {
                ...state,
                storyRef: action.storyRef
            }
        case SET_WHEREBUY_REF:
            return {
                ...state,
                whereBuyRef: action.whereBuyRef
            }
        default:
            return state
    }
}

export const setCollectionRef = (collectionRef) => ({type: SET_COLLECTION_REF, collectionRef})
export const setStoryRef = (storyRef) => ({type: SET_STORY_REF, storyRef})
export const setWherebuyRef = (whereBuyRef) => ({type: SET_WHEREBUY_REF, whereBuyRef})

export default refReducer