
/** Classic Umbrellas thumb **/
import blommorClassic from '../assets/img/art_select_2/classic/blommor/blommor_bot.jpg'
import blommorClassic2 from '../assets/img/art_select_2/classic/blommor/blommor_side.jpg'
import blommorClassic3 from '../assets/img/art_select_2/classic/blommor/blommor_studio.jpg'

import silenceClassic from '../assets/img/art_select_2/classic/silence/silence_bot.jpg'
import silenceClassic2 from '../assets/img/art_select_2/classic/silence/silence_side.jpg'
import silenceClassic3 from '../assets/img/art_select_2/classic/silence/silence_studio.jpg'

import smileClassic from '../assets/img/art_select_2/classic/smile/smile_bot.jpg'
import smileClassic2 from '../assets/img/art_select_2/classic/smile/smile_side.jpg'
import smileClassic3 from '../assets/img/art_select_2/classic/smile/smile_studio.jpg'

import weareClassic from '../assets/img/art_select_2/classic/weare/weare_bot.jpg'
import weareClassic2 from '../assets/img/art_select_2/classic/weare/weare_side.jpg'
import weareClassic3 from '../assets/img/art_select_2/classic/weare/weare_studio.jpg'

import wonderfulClassic from '../assets/img/art_select_2/classic/wonderful/wonderful_bot.jpg'
import wonderfulClassic2 from '../assets/img/art_select_2/classic/wonderful/wonderful_side.jpg'
import wonderfulClassic3 from '../assets/img/art_select_2/classic/wonderful/wonderful_studio.jpg'

import classic from '../assets/img/art_select_2/classic/classic.jpg'

/** Compact Umbrellas thumb **/
import dotsCompact from '../assets/img/art_select_2/compact/dots/dots_bot.jpg'
import dotsCompact2 from '../assets/img/art_select_2/compact/dots/dots_side.jpg'
import dotsCompact3 from '../assets/img/art_select_2/compact/dots/dots_studio.jpg'

import flowersCompact from '../assets/img/art_select_2/compact/flowers/flowers_bot.jpg'
import flowersCompact2 from '../assets/img/art_select_2/compact/flowers/flowers_side.jpg'
import flowersCompact3 from '../assets/img/art_select_2/compact/flowers/flowers_studio.jpg'

import infinityCompact from '../assets/img/art_select_2/compact/infinity/infinity_bot.jpg'
import infinityCompact2 from '../assets/img/art_select_2/compact/infinity/infinity_side.jpg'
import infinityCompact3 from '../assets/img/art_select_2/compact/infinity/infinity_studio.jpg'

import goldentreeCompact from '../assets/img/art_select_2/compact/golden_tree/goldentree_bot.jpg'
import goldentreeCompact2 from '../assets/img/art_select_2/compact/golden_tree/goldentree_side.jpg'
import goldentreeCompact3 from '../assets/img/art_select_2/compact/golden_tree/goldentree_studio.jpg'

import justanotherwinterCompact from '../assets/img/art_select_2/compact/justanotherwinter/justanotherwinter_bot.jpg'
import justanotherwinterCompact2 from '../assets/img/art_select_2/compact/justanotherwinter/justantoherwinter_side.jpg'
import justanotherwinterCompact3 from '../assets/img/art_select_2/compact/justanotherwinter/justantoherwinter_studio.jpg'

import compact from'../assets/img/art_select_2/compact/compact.jpg'



const SET_SELECTED_UMBRELLA = 'SET_SELECTED_UMBRELLA'
const SET_SLIDER = 'SET_SLIDER'
const SET_CURRENT_UMBRELLAS = 'SET_CURRENT_UMBRELLAS'
const SET_ZOOMED_IMG = 'SET_ZOOMED_IMG'

let initialState = {
  selectedUmbrella: {
    id: 1,
    name: 'Blommor',
    cover: blommorClassic,
    images: [blommorClassic3, blommorClassic, blommorClassic2, classic]
  },
  zoomedImg: null,
  currentModel: {
    specifications: [
      {id: 0, text: "Classic umbrella with a wooden handle."},
      {id: 1, text: "Double layer pongee fabric"},
      {id: 2, text: "8 steel and fibreglass ribs"},
      {id: 3, text: "Auto-open mechanism"},
      {id: 4, text: "Wooden crook handle"},
      {id: 5, text: "Nickel-aluminium tip cup"},
      {id: 6, text: "90 cm / 495 grams"},
      {id: 7, text: "105 cm canopy"}
    ],
    umbrellas: [
      {
        id: 1,
        name: 'Blommor',
        cover: blommorClassic,
        images: [blommorClassic3, blommorClassic,blommorClassic2,classic]
      },
      {
        id: 2,
        name: 'Silence',
        cover: silenceClassic,
        images: [silenceClassic3,silenceClassic, silenceClassic2,classic]
      },
      {
        id: 3,
        name: 'Smile',
        cover: smileClassic,
        images: [smileClassic3,smileClassic,smileClassic2,classic]
      },
      {
        id: 4,
        name: 'We Are',
        cover: weareClassic,
        images: [weareClassic3,weareClassic, weareClassic2,classic]
      },
      {
        id: 5,
        name: 'Wonderful Cloud',
        cover: wonderfulClassic,
        images: [wonderfulClassic3,wonderfulClassic, wonderfulClassic2,classic]
      }
    ]
  },
  models: {
    classic: {
      specifications: [
        {id: 0, text: "Classic umbrella with a wooden handle."},
        {id: 1, text: "Double layer pongee fabric"},
        {id: 2, text: "8 steel and fibreglass ribs"},
        {id: 3, text: "Auto-open mechanism"},
        {id: 4, text: "Wooden crook handle"},
        {id: 5, text: "Nickel-aluminium tip cup"},
        {id: 6, text: "90 cm / 495 grams"},
        {id: 7, text: "105 cm canopy"}
      ],
      umbrellas: [
        {
          id: 1,
          name: 'Blommor',
          cover: blommorClassic,
          images: [blommorClassic3,blommorClassic,blommorClassic2,classic]
        },
        {
          id: 2,
          name: 'Silence',
          cover: silenceClassic,
          images: [silenceClassic3,silenceClassic, silenceClassic2,classic]
        },
        {
          id: 3,
          name: 'Smile',
          cover: smileClassic,
          images: [smileClassic3,smileClassic,smileClassic2,classic]
        },
        {
          id: 4,
          name: 'We Are',
          cover: weareClassic,
          images: [weareClassic3,weareClassic, weareClassic2,classic]
        },
        {
          id: 5,
          name: 'Wonderful Cloud',
          cover: wonderfulClassic,
          images: [wonderfulClassic3,wonderfulClassic, wonderfulClassic2,classic]
        }
      ]

    },
    compact: {
      specifications: [
        {id: 0, text: "Compact umbrella. Gift box included."},
        {id: 1, text: "Double layer pongee fabric"},
        {id: 2, text: "8 steel and fibreglass ribs"},
        {id: 3, text: "Auto-open and auto-close"},
        {id: 4, text: "Wooden handle"},
        {id: 5, text: "Beautiful gift box"},
        {id: 6, text: "29 cm / 440 grams"},
        {id: 7, text: "95 cm canopy"},
      ],
      umbrellas: [
        {
          id: 6,
          name: 'Dots',
          cover: dotsCompact,
          images: [dotsCompact3,dotsCompact, dotsCompact2,compact]
        },
        {
          id: 7,
          name: 'Flowers',
          cover: flowersCompact,
          images: [flowersCompact3,flowersCompact, flowersCompact2,compact]
        },
        {
          id: 8,
          name: 'Infinity',
          cover: infinityCompact,
          images: [infinityCompact3,infinityCompact, infinityCompact2,compact]
        },
        {
          id: 9,
          name: 'Golden Tree',
          cover: goldentreeCompact,
          images: [goldentreeCompact3,goldentreeCompact, goldentreeCompact2,compact]
        },
        {
          id: 10,
          name: 'Just Another Winter',
          cover: justanotherwinterCompact,
          images: [justanotherwinterCompact3,justanotherwinterCompact, justanotherwinterCompact2,compact]
        },
      ]
    }
  },
  sliders: {
    nav1: null,
    nav2: null
  },
}

const umbrellaReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_UMBRELLA:
      return {
        ...state,
        selectedUmbrella: action.selectedUmbrella
      }
    case SET_SLIDER:
      return {
        ...state,
        sliders: action.sliderId === 'nav1' ? {...state.sliders, nav1: action.slider}
          : {...state.sliders, nav2: action.slider}
      }
    case SET_CURRENT_UMBRELLAS:
      return {
        ...state,
        currentModel: action.option === 'classic' ? state.models.classic
          : action.option === 'compact' ? state.models.compact : null
      }
    case SET_ZOOMED_IMG:
      return {
        ...state,
        zoomedImg: state.selectedUmbrella.images[action.id]
      }
    default:
      return state
  }
}

/* Action Creators */
export const setSelectedUmbrella = (selectedUmbrella) => ({type: SET_SELECTED_UMBRELLA, selectedUmbrella})
export const setSlider = (sliderId, slider) => ({type: SET_SLIDER, sliderId, slider})
export const setCurrentUmbrellas = (option) => ({type: SET_CURRENT_UMBRELLAS, option})
export const setZoomedImg = (id) => ({type: SET_ZOOMED_IMG, id})

export default umbrellaReducer
