const SET_OPENED_BURGER = 'SET_OPENED_BURGER'

let initialState = {
    openedBurger: true
}

const globalReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_OPENED_BURGER:
            return {
                ...state,
                openedBurger: action.openedBurger
            }
        default:
            return state
    }
}

export const setOpenedBurger = (openedBurger) => ({type: SET_OPENED_BURGER, openedBurger})

export default globalReducer