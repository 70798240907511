import React from 'react'
import styles from "./Socials.module.css"

const Social = (props) => {
    return(
        <li className={styles.socials__item}>
            <a href={props.link} target={'_blank'}><img src={props.img} alt="Social"/></a>
        </li>
    )
}

export default Social