import pinterest from "../assets/img/pinterest.svg"
import instagram from "../assets/img/instagram.svg"
import fb from "../assets/img/fb.svg"

let initialState = {
    socials: [
        {
            id: 1,
            img: pinterest,
            link: 'https://www.pinterest.se/zontjkdesign/'
        },
        {
            id: 2,
            img: instagram,
            link: 'https://www.instagram.com/zontjkdesign/'
        },
        {
            id: 3,
            img: fb,
            link: 'https://www.facebook.com/zontjkdesign.se'
        },
    ]
}

const socialsReducer = (state = initialState, action) => state

export default socialsReducer
