import React, {useState} from 'react'
import './App.css'
import HomePage from "./components/HomePage/HomePage"
import smoothscroll from 'smoothscroll-polyfill'
import {BrowserRouter as Router, Route, Switch} from "react-router-dom"
import AboutContainer from "./components/About/AboutContainer"
import { Ripple } from 'react-preloaders'
import Policy from "./components/Policy/Policy";
import Footer from "./components/Footer/Footer"
import {Transition, TransitionGroup} from "react-transition-group"

smoothscroll.polyfill()

const App = (props) => {
  let [isBackToHome, setIsBackToHome] = useState(false);

  return (
    <>
        <div className="App">
        <Router basename={'/'}>
            <Route render={({location}) => {
                const {key} = location

                return(
                    <TransitionGroup component={null}>
                        <Transition key={key}
                                    appear={true}
                                    timeout={{enter: 1000, exit: 1000}}
                                    >
                            <Switch location={location}>
                                <Route exact path='/' render={() => <HomePage isBackToHome={isBackToHome} setIsBackToHome={setIsBackToHome} />} />
                                <Route path='/about' render={() => <AboutContainer setIsBackToHome={setIsBackToHome} /> } />
                              {/* eslint-disable-next-line react/jsx-no-undef */}
                                <Route path='/policy' render={() => <Policy setIsBackToHome={setIsBackToHome} /> } />
                            </Switch>
                        </Transition>
                    </TransitionGroup>
                )
            }} />
            </Router>
            <Footer/>
        </div>
        <Ripple className='my-preloader' background="#F7F7F7" animation="slide" time={1200} />
    </>
  )
}


export default App
