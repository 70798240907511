import React, {useState, useEffect} from 'react'
import styles from './Story.module.css'
import signature from '../../assets/img/name.svg'
import {NavLink} from 'react-router-dom'
import {Parallax} from "react-scroll-parallax"
import {Element} from 'react-scroll'
import {Title} from "../common/ui"

const Story = (props) => {
    let [isAnimatedElem, setIsAnimatedElem] = useState(false)
    // let [animateZlata, setAnimateZlata] = useState(false)
    let animatedItemRef = React.createRef()

    /** Animate Girl **/
    // let handleReadMore = () => {
    //     setAnimateZlata(true);
    //     animatedItemRef.scrollIntoView({
    //         behavior: 'smooth',
    //         block: 'start',
    //     })
    // }

    useEffect(() => {
      let animatedItemOffsetTop = 0

      let handleScroll = () => {
        let scrollBottom = window.scrollY + window.innerHeight - 500
        if(scrollBottom > animatedItemOffsetTop) setIsAnimatedElem(true)
      }
        window.addEventListener('scroll', handleScroll)
        animatedItemOffsetTop = animatedItemRef.getBoundingClientRect().top

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [animatedItemRef])

    return(
        <section id={'story'} className={`storySection ${styles.storySection}`} ref={r => (animatedItemRef = r)} >{/* style={{backgroundImage: `url(${storyGirl})`}} */}
            <Element name="StorySection">
                <div className={`container storySection__wrapper ${styles.storySection__wrapper} ${isAnimatedElem && styles.animate}`}>
                    <Parallax className={`${styles.storySection__info_parallax}`} y={[60, -50]} tagOuter="div">
                        <div className={`storySection__info ${styles.storySection__info}`}>
                            <div className={`container ${styles.storySection__container}`}>
                                <Title dark story__title >Story behind our <br/> umbrellas</Title>
                                <figure className={styles.storySection__author_signature}>
                                    <img src={signature} alt="Signature"/>
                                </figure>
                                <div className={styles.storySection__info_text}>
                                    <p>A perfect gift for someone you care about or for yourself to brighten rainy day – Zontjk creates high quality umbrellas with unique artwork. Gorgeous Zontjk canopies are actual, real art pieces of professional Swedish painter Zlata Jaanimägi, who via sharing her art outside of the gallery wants to improve even the most rainy and gloomy day. This is a story of how art-gallery art became something for everyone.
                                    </p>
                                </div>
                                <NavLink to='/about' /** onClick={handleReadMore} **/
                                         className={`drop_btn ${styles.storySection__btn}`}>
                                    <span className={`drop_btn__circle`}></span>READ MORE</NavLink>
                            </div>
                        </div>
                    </Parallax>
                </div>
            </Element>
        </section>
    )
}

export default Story
