import React from 'react'
import styled, {css} from 'styled-components'
import {device} from '../../common/ui'
import logoImg from '../../../assets/img/logo.svg'

let MobileLogo = styled.figure`
    display: none;
    
    @media ${device.laptopM} {
        display: block;
        padding: 30px;
        width: 150px;
        cursor: pointer;
        overflow: hidden;
        // opacity: 0;
        transition: all .45s;
        
        ${props => props.show && css`
             opacity: 1;
        `}
        
        > img {
            width: 100%;
        }
    }
`

export default props => (
    <MobileLogo {...props}><img src={logoImg} alt="Logo"/></MobileLogo>
)