import React, {useEffect} from 'react';
import cn from "classnames";
import styles from "../Collection.module.css";
import ReactCursorPosition, {INTERACTIONS} from "react-cursor-position";
import Slider from "react-slick/lib";
import ReactImageMagnify from "react-image-magnify";
import {Zoom} from "../Zoom";
import PinchZoomPan from "react-responsive-pinch-zoom-pan";
import {disableScroll, enableScroll} from "../../utils/helpers";

const Sliders = (props) => {


  let windowWidth = window.innerWidth,
    sliderSmall = null,
    sliderBig = null,
    modalBigSlider = null,
    modalSmallSlider = null;

  let showModal = () => {
    if (windowWidth >= 1200) {
      props.setIsShowModal(true)
      disableScroll()
    }
  };
  let closeModal = (e) => {
    if (e.target.className.includes('modalSlider')) {
      props.setIsShowModal(false);
      enableScroll()
    }
  };


  let afterChangeHandler = (currentSlide) => {
    sliderBig.slickGoTo(currentSlide)
    props.setCurrentSliderIndex(currentSlide)
    modalSmallSlider.slickGoTo(currentSlide)
    modalBigSlider.slickGoTo(currentSlide)
    props.setZoomedImg(currentSlide)
  }

  const showZoom = () => {
    props.setShowZoomedImg(true)
    disableScroll()
  }


  let bigSliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    swipeToSlide: true,
    fade: false,
    // afterChange: afterChangeHandler,

  }

  let sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    focusOnSelect: true,
    swipeToSlide: true,
    variableWidth: true
  }

  useEffect(() => {
    props.setSlider('nav1', sliderBig)
    props.setSlider('nav2', sliderSmall)
  }, [sliderBig, sliderSmall]);

  return (
    <div className={cn(styles.collection__top, {[styles.animate]: props.isChangedArt})}>
      {windowWidth < 1200 && <div className={cn('collection container', styles.collection__dark)}>
      </div>}
      <div
        className={cn('collection__top_slider', styles.collection__top_slider, {[styles.animate]: props.isChangedArt})}
        onClick={showModal}>
        <ReactCursorPosition activationInteractionMouse={INTERACTIONS.HOVER} hoverDelayInMs={250}
                             hoverOffDelayInMs={150}>
          <Slider {...bigSliderSettings} asNavFor={sliderSmall} ref={slider => (sliderBig = slider)}>
            {props.selectedUmbrella.images.map((item, index) =>
              <div key={index} className={styles.collection__top_slider_item}>
                {windowWidth < 1200 && <img src={item} alt="Img"/>}
                {windowWidth >= 1200 && <ReactImageMagnify {...{
                  smallImage: {
                    alt: 'Umbrella',
                    isFluidWidth: true,
                    src: item,
                  },
                  largeImage: {
                    src: item,
                    width: 1200,
                    height: 1200,
                  },
                  className: 'zoomImageRoot',
                  enlargedImageContainerClassName: 'largeImage'
                }} />}
              </div>)}
          </Slider>
          {windowWidth < 1200 && <div className={styles.zoom} onClick={showZoom}></div>}
          {windowWidth >= 1200 && <Zoom/>}
        </ReactCursorPosition>
      </div>
      <ul className={cn('artList__slider', styles.artList)}>
        <Slider {...sliderSettings} ref={r => (sliderSmall = r)}>
          {props.selectedUmbrella.images.map((item, index) =>
            <li key={index}
                className={`${props.currentSliderIndex === index && 'active'}`}
                onClick={() => afterChangeHandler(index)}
            >
              <span><img src={item} alt="Cover"/></span>
            </li>)}
        </Slider>
      </ul>
      <div className={`modalSlider ${props.isShowModal && 'show'}`} onClick={closeModal}>
        <div className={'modalSlider__big'}>
          <Slider {...bigSliderSettings} variableWidth={true} centerMode={true} variableHeight={true}
                  ref={r => (modalBigSlider = r)}>
            {props.selectedUmbrella.images.map((item, index) =>
              <div
                key={index}
                className={styles.collection__top_slider_item}
              >
                {windowWidth < 1200 && <img src={item} alt="Img"/>}
                {windowWidth >= 1200 &&
                <PinchZoomPan zoomButtons={false} maxScale={2}>
                  <img src={item} alt="Umbrella"/>
                </PinchZoomPan>
                }
              </div>)}
          </Slider>
        </div>
        <div className={'modalSlider__small'}>
          <ul className={`${styles.artList} artList__slider`}>
            <Slider {...sliderSettings} asNavFor={sliderBig} ref={r => (modalSmallSlider = r)}>
              {props.selectedUmbrella.images.map((item, index) => <div key={index}>
                <li className={`${props.currentSliderIndex === index && 'active'}`} onClick={() => {
                  afterChangeHandler(index)
                }}>
                  <span><img src={item} alt="Cover"/></span>
                </li>
              </div>)}
            </Slider>
          </ul>
        </div>
      </div>
    </div>
  )
}
export default Sliders
