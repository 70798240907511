import {connect} from "react-redux"
import Story from "./Story"
import React from "react"

const StoryContainer = (props) => {
    return <Story {...props} />
}

const mapStateToProps = (state) => ({
    storesRef: state.stores.storesRef
})

export default connect(mapStateToProps, null)(StoryContainer)