import React from 'react'
import styles from "./Home.module.css"
import HeaderContainer from "../Header/HeaderContainer"
import umbrellaImg1 from '../../assets/img/mainSlider/landbg.jpg'
import umbrellaImg2 from '../../assets/img/mainSlider/landbg2.jpg'
import umbrellaImg3 from '../../assets/img/mainSlider/landbg3.jpg'
import Slider from 'react-slick'
import arrowLeftImg from '../../assets/img/arrows/arrow_mob_left.svg'
import arrowRightImg from '../../assets/img/arrows/arrow_mob_right.svg'
import {enableScroll} from "../utils/helpers"
import logoImg from "../../assets/img/logo.svg"
import {Logo} from "./Logo"

const UmbrellaArray = [
  {
    img: umbrellaImg1,
    alt: 'Umbrella'
  },
  {
    img: umbrellaImg2,
    alt: 'Umbrella'
  },
  {
    img: umbrellaImg3,
    alt: 'Umbrella'
  },
  // {
  //   img: umbrellaImg1,
  //   alt: 'Umbrella'
  // },
]

const titles = ['Real art umbrellas', 'Premium quality', 'Unique design', 'Created in Sweden']

let windowWidth = window.innerWidth,
  homeRef = null

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isVisibleUmbrella: true,
      isLoadedPage: false,
      deleteDelay: false,
      changeBurgerColor: false,
      isHandleLogoUp: false,
      currentSliderIndex: `01`,
      currentSliderIndexNum: 0,
      subtitle: '',
      isTitleStokeDeleted: false
    }
  }

  next = () => {
    this.slider.slickNext()
    if (titles.length - 1 > this.state.currentSliderIndexNum) {
      this.setState({currentSliderIndexNum: this.state.currentSliderIndexNum + 1})
    }
  }

  previous = (e) => {
    this.slider.slickPrev()
    if (this.state.currentSliderIndexNum >= 1) {
      this.setState({currentSliderIndexNum: this.state.currentSliderIndexNum - 1})
    }
  }

  setUmbrellaTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }

  scrollToCollection = () => {
    this.props.collectionRef.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
    this.props.setOpenedBurger(false)
    enableScroll()
  }

  handleScroll = (e) => {
    if (window.scrollY > (homeRef.getBoundingClientRect().height - 100)) {
      this.setState({changeBurgerColor: true})
    } else {
      this.setState({changeBurgerColor: false})
    }
  }

  handleLogoUp = () => {
    this.setUmbrellaTop()
    this.setState({isHandleLogoUp: true})
    setTimeout(() => {
      this.setState({isHandleLogoUp: false})
    }, 1000)
    this.props.setOpenedBurger(false)
    enableScroll()
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    // window.onbeforeunload = this.setUmbrellaTop
    // this.setUmbrellaTop()

    setTimeout(() => {
      this.setState(state => ({
        isLoadedPage: true
      }))
    }, 1200)

    setTimeout(() => {
      this.setState({isTitleStokeDeleted: true})
    }, 7000)

    if (windowWidth > 1200) {
      setTimeout(() => {

        this.setState({
          deleteDelay: true
        })
      }, 2000)
    }

    this.props.setZoomedImg(0);
    setTimeout(() => {
      this.props.setIsBackToHome(false)
    }, 3000);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)

  }

  render() {
    const Settings = {
      infinite: true,
      fade: true,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 4000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: false,
      beforeChange: (oldIndex, newIndex) => {
        let currentIndex = newIndex + 1

        this.setState({
          currentSliderIndex: currentIndex < 10 ? `0${currentIndex}` : currentIndex
        })
      },
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            dots: true
          }
        }
      ]
    }

    return (
      <div className={`home ${styles.home} ${this.state.isLoadedPage && styles.loadedPage}
            ${this.state.isVisibleUmbrella && styles.showUmbrella}`} ref={refSection => (homeRef = refSection)}>
        <HeaderContainer changeBurgerColor={this.state.changeBurgerColor} handleLogoUp={this.handleLogoUp}
                         isHandleLogoUp={this.state.isHandleLogoUp}/>
        <div className={`container ${styles.homeLogo__container}`}>
          <Logo><img src={logoImg} alt="Logo"/></Logo>
        </div>
        <div className={styles.home__info}>
          <div className={`${styles.home__subtitle}`}>
            {/*<Typist>*/}
            {/*    <Typist.Delay ms={2000} />*/}
            {titles.map((title, index) =>
              <div key={index} className={`${styles.home__subtitle_wrapper} ${index === 0 && styles.active}`}>
                <p className={`${index === 0 ? styles.home__title : styles.home__subtitle_item} 
                            ${this.state.isTitleStokeDeleted && styles.delete_storke}`}><span>{title}</span></p></div>)}
            {/*</Typist>*/}
          </div>
          <div className={`container ${styles.home__btn_wrapper}`}>
            <button className={`btn ${styles.home__btn} ${this.state.deleteDelay && styles.deleteDelay}`}
                    onClick={this.scrollToCollection}>View our collection
            </button>
            <div className={`${styles.sliderBtns__wrapper} ${this.state.isLoadedPage && styles.show}`}>
              {windowWidth >= 1200 && <div className={styles.sliderCounter}>
                <span className={styles.sliderCounter__current}>{this.state.currentSliderIndex}</span>
                <span className={styles.sliderCounter__sepo}>/</span>
                <span className={styles.sliderCounter__total}>
                                    {UmbrellaArray.length < 10 ? `0${UmbrellaArray.length}` : UmbrellaArray.length}
                                </span>
              </div>}
              <div className={styles.sliderBtns}>
                <figure className={`${styles.sliderBtns__arrow} ${styles.sliderBtns__arrow_left} noselect`}
                        onClick={this.previous}>
                  <img src={arrowLeftImg} alt="Arrow Left"/>
                </figure>
                <figure className={`${styles.sliderBtns__arrow} ${styles.sliderBtns__arrow_right} noselect`}
                        onClick={this.next}>
                  <img src={arrowRightImg} alt="Arrow Right"/>
                </figure>
              </div>
            </div>
          </div>
          {/*windowWidth >= 1600 &&*/}
          {<div className='mainSlider'>
            <Slider ref={c => (this.slider = c)} {...Settings}>
              {UmbrellaArray.map((umbrella, index) =>
                <div key={index} className={`umbrella__wrapper ${this.props.isBackToHome && 'isBackToHome'}`}>
                  <div className='umbrella__container'>
                    <figure className={`umbrella ${this.state.isLoadedPage && 'active'}`}>
                      <img key={index} src={umbrella.img} alt={umbrella.alt}/>
                    </figure>
                  </div>
                </div>
              )}
            </Slider>
          </div>}
        </div>
        {/*{windowWidth < 1600 && <figure className={`umbrella ${this.state.isLoadedPage && 'active'}`}>*/}
        {/*    <Slider ref={c => (this.slider = c)} {...Settings}>*/}
        {/*        {UmbrellaArray.map((umbrella, index) =>*/}
        {/*            <img key={index} src={umbrella.img} alt={umbrella.alt}/>)}*/}
        {/*    </Slider>*/}
        {/*</figure>}*/}
        {windowWidth >= 1200 &&
        <button className={`logo ${this.state.isHandleLogoUp && 'up'}`} onTouchStart={this.handleLogoUp}
                onClick={this.handleLogoUp}>
          <span></span>
        </button>}
      </div>
    )
  }
}

export default Home
