import React, {useEffect, useState} from 'react'
import cn from './About.module.css'
import storyImg1 from '../../assets/img/fotki/box.jpg'
import storyImg2 from '../../assets/img/fotki/meshup.jpg'
import storyImg3 from '../../assets/img/fotki/dog.jpg'
import storyImg4 from '../../assets/img/fotki/4.jpg'
import storyImg5 from '../../assets/img/fotki/bg5.jpg'
import nImg from '../../assets/img/bukvy/n.svg'
import kImg from '../../assets/img/bukvy/k.svg'
import jImg from '../../assets/img/bukvy/j.svg'
import tImg from '../../assets/img/bukvy/t.svg'
import oImg from '../../assets/img/bukvy/o.svg'
import lImg from '../../assets/img/bukvy/l.svg'
import {NavLink} from "react-router-dom"
import Scroll from 'react-scroll'
import {Parallax} from 'react-scroll-parallax'
import {goTop} from "../utils/helpers"
import StoresContainer from "../AboutStores/StoresContainer";

const About = (props) => {
  let [isPageLoaded, setIsPageLoaded] = useState(false)
  let [isLogoTop, setIsLogoTop] = useState(false)
  let [isComponentMounted, setIsComponentMounted] = useState(false)

  let scrollToStorySection = () => {
    window.scrollTo(0, 0)
    setIsComponentMounted(false)
    props.setIsBackToHome(true)
  }

  let handleLogoTop = () => {
    setIsLogoTop(true)
    setTimeout(() => {
      setIsLogoTop(false)
    }, 1000)
    goTop()
  }

  useEffect(() => {

    setTimeout(() => {
      setIsPageLoaded(true)
      setIsComponentMounted(true)
      window.scrollTo(0, 0)
    }, 1000)

    return () => {
      setTimeout(() => {
        Scroll.scroller.scrollTo('StorySection', {
          duration: 0,
          delay: 0,
          smooth: false,
          offset: 0,
        })

      }, 0)
    }
  }, [])

  return (
    <div
      className={`aboutPage ${cn.about} ${isPageLoaded && cn.pageIsLoaded} ${isComponentMounted ? 'mounted' : 'unmounted'}`}>
      <div className={`${cn.container} ${cn.about__top}`}>
        <div className={'logo_top__wrapper'}>
          <button className={`logo_top ${isLogoTop && 'up'}`} onClick={handleLogoTop}></button>
        </div>
        <div className={cn.back_btn__wrapper}>
          <NavLink to={'/'} className={cn.back_btn} onClick={scrollToStorySection}>Back to main</NavLink>
        </div>
        <h1 className={`${cn.about__title} animate_to_top ${isPageLoaded && 'animate'}`}>Story behind <br/>our umbrellas
        </h1>
        <div className={`${cn.about__subtitle} animate_to_top ${isPageLoaded && 'animate'}`}>
          <p>The story of Zontjk umbrellas began in 2015.
            The person behind this story is Zlata Jaanimägi – a professional artist and multimedia designer.</p>
        </div>
        <div className={cn.columns}>
          <div className={`${cn.text} ${cn.animated_top_text} animate_to_top ${isPageLoaded && 'animate'}`}>
            <p>Nice to meet you.</p>
            <p>For over 10 years I have been participating in art exhibitions and art-projects all around the world.
              Exposing my work and sharing it with the world has always been enjoyable and gratifying, but over the
              years I couldn’t shake the feeling of “a-time-and-a-place” attitude towards art. That made me think.</p>
          </div>
          <figure className={`${cn.columns__img} ${cn.columns__img_top} ${isPageLoaded && cn.animate}`}>
            <img src={storyImg1} alt="Story"/>
            <Parallax className={`${cn.letters} ${cn.first}`} y={[60, -500]} tagOuter="div">
              <span className={`${cn.letter} ${cn.first}`}><img src={nImg} alt="Letter"/></span>
            </Parallax>
            <Parallax className={`${cn.letters} ${cn.first__2}`} y={[55, -720]} tagOuter="div">
              <span className={`${cn.letter} ${cn.first__2}`}><img src={lImg} alt="Letter"/></span>
            </Parallax>
          </figure>
        </div>
        <div className={`${cn.columns} ${cn.img_first}`}>
          <figure className={`${cn.columns__img} ${cn.columns__img_top} ${isPageLoaded && cn.animate}`}
                  style={{background: `url(${storyImg2}) no-repeat center center/cover`}}>
            {/*<img src={storyImg} alt="Story Image"/>*/}
            <Parallax className={`${cn.letters} ${cn.second}`} y={[50, -950]} tagOuter="div">
              <span className={`${cn.letter} ${cn.second}`}><img src={kImg} alt="Letter"/></span>
            </Parallax>
            <Parallax className={`${cn.letters} ${cn.third}`} y={[-50, 800]} tagOuter="div">
                            <span className={`${cn.letter} ${cn.third}`}>
                                <img src={jImg} style={{
                                  width: 'auto',
                                  height: '100%',
                                  display: 'block',
                                  marginLeft: 'auto',
                                }} alt="Letter"/>
                            </span>
            </Parallax>
          </figure>
          <div className={`${cn.text} ${cn.animated_top_text} animate_to_top ${isPageLoaded && 'animate'}`}>
            <p>I believe the value of art is not in its exclusivity, but in that it adds beauty to our lives, makes it
              more interesting. Art evokes emotion. This emotion should not be limited to exhibitions, galleries, and
              private collections. Art deserves to be a part of our daily lives, to share this emotion not just with the
              highbrow audience on designated exhibition dates, but to everyone, every passerby on the street.
            </p>
          </div>
        </div>
      </div>
      <figure className={cn.fullImg} style={{backgroundImage: `url(${storyImg3})`}}>
        <div className={cn.container}>
          <Parallax className={`${cn.letters} ${cn.fourth}`} y={[-50, 600]} tagOuter="div">
                        <span className={`${cn.letter} ${cn.fourth}`}>
                            <img src={tImg} alt="Letter"/>
                        </span>
          </Parallax>
          <Parallax className={`${cn.letters} ${cn.fifth}`} y={[260, -1360]} tagOuter="div">
                        <span className={`${cn.letter} ${cn.fifth}`}>
                            <img src={oImg} alt="Letter"/>
                        </span>
          </Parallax>
        </div>
      </figure>
      <div className={`${cn.container} ${cn.about__center}`}>
        <div className={cn.columns}>
          <div className={cn.text}>
            <p>This reflection produced the idea of an «everyday canvas». A canvas that you can bring along while you go
              about your business or for a walk in a park.
            </p>
            <p>Typical Scandinavian weather swiftly gave form to this idea – umbrellas with an art canopy.
            </p>
            <p>The idea was followed by a lengthy period of preparation. Print mockups for various canopy types,
              projecting, planning, choosing the right material for the canopy, a convenient and reliable mechanism. I
              had to dive deep into the manufacturing side of things, figure out all the different types of umbrella
              mechanisms, components and materials.
            </p>
            <p>Finding the right manufacturer of the umbrellas proved to be most difficult and time-consuming, as a
              guarantee of high quality and durability was an absolute must. Zontjk cannot tolerate substandard quality
              – while art canopies will speak for themselves, the technical side of the product needs to stay out of the
              conversation altogether by being seamlessly perfect.
            </p>
            <p>Finally, after 2 years Zontjk umbrellas started appearing on the streets of Stockholm.</p>
          </div>
          <Parallax className={cn.columns__img} y={[50, -50]} tagOuter="figure">
            <img src={storyImg4} alt="Story"/>
          </Parallax>
        </div>
      </div>
      <figure className={cn.fullImg} style={{backgroundImage: `url(${storyImg5})`}}></figure>
      <div className={`${cn.container} ${cn.about__bottom}`}>
        <div className={`${cn.about__subtitle} ${cn.about__bottom_subtitle}`}>
          <p>I’m really glad I can help people improve their day even if it’s particularly rainy and grey. With my art
            and designer umbrellas, anyone can create their own sky.
          </p>
        </div>
      </div>
      <StoresContainer/>
    </div>
  )
}

export default About
