import React from 'react'
import {connect} from "react-redux"
import About from "./About"
import { ParallaxProvider } from 'react-scroll-parallax'

const AboutContainer = (props) => {
    return <ParallaxProvider><About {...props} /></ParallaxProvider>
}

const mapStateToProps = (state) => ({
    whereBuyRef: state.refs.whereBuyRef
})

export default connect(mapStateToProps, null)(AboutContainer)