import React from 'react'
import {connect} from "react-redux"
import MobileMenu from "./MobileMenu"

const MobileMenuContainer = (props) => {
    return <MobileMenu {...props} />
}

const mapStateToProps = (state) => ({
    collectionRef: state.refs.collectionRef
})

export default connect(mapStateToProps, null)(MobileMenuContainer)