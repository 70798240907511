import React from 'react'
import styles from "../Header.module.css"

const Burger = ({position: {x,y}, isActive, changeBurger, openedBurger, changeBurgerColor, ...props}) => {
    // let isMobile = window.innerWidth < 1200 ? true : false

    return(
        <div className={`${styles.burger__wrapper} ${!props.isOnTop && styles.show}`} onClick={changeBurger}>
            <div className={`${styles.burger} ${openedBurger && styles.active} 
                        ${changeBurgerColor && styles.change_color}` } >
                <span className={styles.burger__title}>menu</span>
                <div className={styles.burger__bar_wrapper} style={{
                    // top: y && isActive && !isMobile && !openedBurger ? `calc(50% + ${(y / 8) }px)` : '50%',
                    // left: x && isActive && !isMobile && !openedBurger ? `calc(50% + ${(x / 8) }px)` : '50%',
                }}>
                    <span className={styles.burger__bar}></span>
                    <span className={`${styles.burger__bar}`}></span>
                </div>
                <span className={styles.burger__circle} style={{
                    // top: y && isActive && !isMobile && !openedBurger ? `calc(50% + ${(y / 4) }px)` : '50%',
                    // left: x && isActive && !isMobile && !openedBurger ? `calc(50% + ${(x / 4) }px)` : '50%',
                }}></span>
            </div>
        </div>
    )
}

export default Burger