import React from 'react'
import styles from "../ArtList/ArtList.module.css"
import {Animated} from "react-animated-css"

export const ArtCovers = ({umbrellas, isChangedArt, selectedUmbrella: {id}, setArt, title, ...props}) => {
  return (
    <div className={styles.artList__container}>
      <p className={styles.artList__title}>{props.title}</p>
      <ul className={`${styles.artList} ${isChangedArt && styles.lock}`}>
        {umbrellas.map(umbrella =>
          <Animated key={umbrella.id} animationIn="fadeIn" animationOut="fadeOut" isVisible={true}>
            <li className={`${id === umbrella.id && styles.active}`}
                onClick={() => setArt(umbrella)}>
              <figure><img src={umbrella.cover} alt="Cover"/></figure>
            </li>
          </Animated>)}
      </ul>
    </div>
  )
}
