import React, {useState, useEffect} from 'react'
import styles from './Header.module.css'
import MobileMenuContainer from "./MobileMenu/MobileMenuContainer"
import {disableScroll, enableScroll, getCookie, setCookie} from "../utils/helpers"
import Burger from "./Burger/Burger"
import ReactCursorPosition, {INTERACTIONS} from "react-cursor-position"

const Header = (props) => {
    let [windowWidth, setWindowWidth] = useState(null)
    let [hideCookie, setHideCookie] = useState(false)
    let [isPageLoaded, setIsPageLoaded] = useState(false)
    let [isCookieExist, setIsCookieExist] = useState(false)

    let onResize = () => {
        setWindowWidth(window.innerWidth)
    }

    useEffect(() => {
        onResize()
        setIsPageLoaded(true)
        if(getCookie('gdpr')) {
            setIsCookieExist(true)
        }
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize)

        return () => {
            window.removeEventListener('resize', onResize)
        }
    },[windowWidth])

    const changeBurger = () => {
        props.setOpenedBurger(!props.openedBurger)
        if(windowWidth < 1200) {
            props.openedBurger ? enableScroll('soft') : disableScroll('soft')
        }
    }

    const handleCookies = () => {
        setHideCookie(true)
        setCookie('gdpr',true,1)
        setIsCookieExist(true)
    }

    return(
        <>
            <header className={`${styles.header} ${!props.openedBurger && props.color && styles.header__color}`}>
                <div className={`${styles.header__container} container`}>
                    <ReactCursorPosition activationInteractionMouse={INTERACTIONS.HOVER}
                                         hoverDelayInMs={0}
                                         hoverOffDelayInMs={0}>
                        <Burger changeBurger={changeBurger} openedBurger={props.openedBurger}
                                color={props.color}
                                changeBurgerColor={props.changeBurgerColor} isOnTop={props.isOnTop}/>
                    </ReactCursorPosition>
                </div>
                <MobileMenuContainer isOpened={props.openedBurger} setOpenedBurger={props.setOpenedBurger}
                                     changeBurgerColor={props.changeBurgerColor} isOnTop={props.isOnTop} handleLogoUp={props.handleLogoUp}
                                     isHandleLogoUp={props.isHandleLogoUp}
                                     color={props.color}
                />
            </header>
            {!isCookieExist && <div className={`${styles.cookies} ${hideCookie && styles.hide} ${props.changeBurgerColor && styles.change_color} ${isPageLoaded && styles.pageLoaded}`}>
                <div className={styles.cookies__text}>
                    <p>We use cookies to provide better browsing experience, analyse site traffic, and serve targeted ads. Accept or ignore this banner to agree to the use of cookies. For more info see our Privacy Policy.</p>
                </div>
                <button className={styles.cookies__btn} onClick={handleCookies}>ACCEPT COOKIES</button>
            </div>}
        </>
    )
}

export default Header
