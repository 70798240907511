import React from 'react'
import {connect} from "react-redux"
import ArtList from "./ArtList"
import {setCurrentUmbrellas, setSelectedUmbrella, setZoomedImg} from "../../../redux/umbrella-reducer"

const ArtListContainer = props => {
    return <ArtList {...props} />
}

const mapStateToProps = (state) => ({
    classicUmbrellas: state.umbrella.models.classic.umbrellas,
    compactUmbrellas: state.umbrella.models.compact.umbrellas,
    specifications: state.umbrella.currentModel.specifications,
    currentId: state.umbrella.selectedUmbrella.id,
    currentUmbrellas: state.umbrella.currentModel.umbrellas,
    nav1: state.umbrella.sliders.nav1,
    nav2: state.umbrella.sliders.nav2,
})

export default connect(mapStateToProps, {setSelectedUmbrella, setZoomedImg, setCurrentUmbrellas})(ArtListContainer)