import React, {useEffect, useRef, useState} from 'react'
import styles from './Policy.module.css'
import HeaderContainer from "../Header/HeaderContainer";
// import {Logo} from "../Home/Logo";
// import logoImg from "../../assets/img/logo.svg";
import {connect} from "react-redux";
import {enableScroll} from "../utils/helpers";

const mapStateToProps = (state) => ({
  imgs: state.umbrella.selectedUmbrella.images,
  nav1: state.umbrella.sliders.nav1,
  collectionRef: state.refs.collectionRef,
  openedBurger: state.global.openedBurger
});

const Policy = (props) => {

  let homeRef = useRef(null);

  const [changeBurgerColor, setChangeBurgerColor] = useState(false);
  const [isHandleLogoUp, setIsHandleLogoUp] = useState(false);
  // const [isLoadPage, setIsLoadPage] = useState(false);

  const handleScroll = (e) => {
    if (window.scrollY > (homeRef.current.getBoundingClientRect().height - 250)) {
      setChangeBurgerColor(true)
    } else {
      setChangeBurgerColor(false)
    }
  }

  const handleLogoUp = () => {
    setIsHandleLogoUp(true)
    setTimeout(() => {
      setIsHandleLogoUp(false);
    }, 1000);
    props.setOpenedBurger(false);
    enableScroll()
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // setTimeout(() => {
    //   setIsLoadPage(true)
    // }, 1200)
  });


  return (
    <div ref={homeRef}>
      <HeaderContainer
        changeBurgerColor={changeBurgerColor}
        handleLogoUp={handleLogoUp}
        isHandleLogoUp={isHandleLogoUp}
        color={true}
      />
      <section id="policy" className={`${styles.policy}`}>
        <div className={`container ${styles.policy__container} ${window.scrollY > 120 && styles.policy__container_hide}`}>
          <h2 className={styles.policy__title}>Privacy Policy</h2>
          <div className={styles.policy__info}>
            <p className={styles.policy__text}>
              ZontjkDesign operates the <a href="https://www.zontjk.com">https://www.zontjk.com</a> website.
            </p>
            <p className={styles.policy__text}>
              Here we inform you of our policies regarding the collection, use, and disclosure of personal data when you
              use our website, and the choices you have associated with that data.
            </p>
            <p className={styles.policy__text}>
              This privacy policy is loosely based on the text provided by FreePrivacyPolicy.com.
            </p>
            <p className={styles.policy__text}>
              We use your data to provide and improve our website and the services we provide. By using the website you
              agree to the collection and use of information in accordance with this policy.
            </p>
          </div>
          <div className={styles.policy__info}>
            <h4 className={styles.policy__name}>
              Collection and use of information
            </h4>
            <p className={styles.policy__text}>We collect cookies and usage data to provide and improve our services.</p>
          </div>
          <div className={styles.policy__info}>
            <h4 className={styles.policy__name}>Types of Data Collected</h4>
            <p className={`${styles.policy__text} ${styles.policy__text_strong}`}>Usage Data</p>
            <p className={styles.policy__text}>We may collect information on how the website is accessed and used. This usage data may include information such as your computer's Internet Protocol address (e.g. IP address),
              browser type, browser version, the pages of our website that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
            <p className={`${styles.policy__text} ${styles.policy__text_strong}`}>Tracking & Cookies Data</p>
            <p className={styles.policy__text}>We use cookies and similar tracking technologies to track the
              activity on our website and hold certain information.</p>
            <p className={styles.policy__text}>Cookies are files with small amount of data which may include an
              anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device.
              Tracking technologies also used are beacons, tags, and scripts to collect and track information and to
              improve and analyze our website.</p>
            <p className={styles.policy__text}>You can instruct your browser to refuse all cookies or to indicate
              when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some
              portions
              of our website.</p>
            <p className={styles.policy__text}>Examples of Cookies we use:</p>
            <ul className={styles.policy__list}>
              <li className={styles.policy__item}>Session Cookies. We use them to operate our website.</li>
              <li className={styles.policy__item}>Analytics cookies. We use them to analyze how our website is
                used.
              </li>
            </ul>
          </div>
          <div className={styles.policy__info}>
            <h4 className={styles.policy__name}>Use of Data</h4>
            <p className={styles.policy__text}>ZontjkDesign uses the collected data for various purposes:</p>
            <ul className={styles.policy__list}>
              <li className={styles.policy__item}>To provide analysis or valuable information so that we can
                improve
                the website and the services we provide
              </li>
              <li className={styles.policy__item}>To monitor the usage of the website</li>
              <li className={styles.policy__item}>To detect, prevent and address technical issues</li>
            </ul>
          </div>
          <div className={styles.policy__info}>
            <h4 className={styles.policy__name}>Use of Data</h4>
            <p className={styles.policy__text}>Your information, including Personal Data, may be transferred to,
              and
              maintained on computers located outside of your state, province, country or other governmental
              jurisdiction
              where the data protection laws may differ than those from your jurisdiction.</p>
            <p className={styles.policy__text}>If you are located outside Sweden and choose to provide information
              to us, please note that we transfer the data, including Personal Data, to Sweden and process it there.</p>
            <p className={styles.policy__text}>Your consent to this Privacy Policy followed by your submission of
              such information represents your agreement to that transfer.</p>
            <p className={styles.policy__text}>ZontjkDesign will take all steps reasonably necessary to ensure
              that
              your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal
              Data will take place to an organization or a country unless there are adequate controls in place including
              the security of your data and other personal information.</p>
          </div>
          <div className={styles.policy__info}>
            <h4 className={styles.policy__name}>Disclosure Of Data</h4>
            <p className={`${styles.policy__text} ${styles.policy__text_strong}`}>Legal Requirements</p>
            <p className={styles.policy__text}>ZontjkDesign may disclose your Personal Data in the good faith
              belief
              that such action is necessary to:</p>
            <ul className={styles.policy__list}>
              <li className={styles.policy__item}>To comply with a legal obligation</li>
              <li className={styles.policy__item}>To protect and defend the rights or property of ZontjkDesign
              </li>
              <li className={styles.policy__item}>To prevent or investigate possible wrongdoing in connection with
                our website
              </li>
              <li className={styles.policy__item}>To protect the personal safety of users of our website or the
                public
              </li>
              <li className={styles.policy__item}>To protect against legal liability</li>
            </ul>
          </div>
          <div className={styles.policy__info}>
            <h4 className={styles.policy__name}>Security Of Data</h4>
            <p className={styles.policy__text}>The security of your data is important to us. We use commercially
              acceptable means to protect your Personal Data and do everything within reason to keep your data
              secure.</p>
          </div>
          <div className={styles.policy__info}>
            <h4 className={styles.policy__name}>Service Providers</h4>
            <p className={styles.policy__text}>We may employ third party companies and individuals to operate and
              maintain our website, to perform website-related services or to assist us in analyzing how our website is
              used.</p>
            <p className={styles.policy__text}>These third parties have access to your Personal Data only to
              perform
              these tasks on our behalf and are obligated not to disclose or use it for any other purpose</p>
          </div>
          <div className={styles.policy__info}>
            <h4 className={styles.policy__name}>Links To Other Sites</h4>
            <p className={styles.policy__text}>Our Service may contain links to other sites that are not operated
              by
              us. If you click on a third party link, you will be directed to that third party's site. We strongly
              advise
              you to review the Privacy Policy of every site you visit.
              We have no control over and assume no responsibility for the content, privacy policies or practices of any
              third party sites or services.</p>
          </div>
          <div className={styles.policy__info}>
            <h4 className={styles.policy__name}>Changes To This Privacy Policy</h4>
            <p className={styles.policy__text}>We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.</p>
            <p className={styles.policy__text}>You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
          </div>
          <div className={styles.policy__info}>
            <h4 className={styles.policy__name}>Contact Us</h4>
            <p className={styles.policy__text}>If you have any questions about this Privacy Policy, please contact
              us at <br/>
              <a href='mailto:info@zontjkdesign.se' className={styles.policy__link}>info@zontjkdesign.se</a>
            </p>

          </div>
        </div>
      </section>
      {window.innerWidth >= 1200 &&
      <button className={`logo ${isHandleLogoUp && 'up'}`} onTouchStart={handleLogoUp}
              onClick={handleLogoUp}>
        <span></span>
      </button>}
    </div>
  )
};
export default connect(mapStateToProps)(Policy)

