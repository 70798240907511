import React, {useState, useEffect} from 'react'
import styles from './Comments.module.css'
import Comment from "./Comment"
import Slider from 'react-slick'
import {Title} from "../common/ui"

let sliderSettings = {
    dots: true,
    arrows: false,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    centerMode: true,
    variableWidth: true,
}

const Comments = ({comments}) => {
    let [windowWidth, setWindowWidth] = useState(null)

    let onResize = () => {
        setWindowWidth(window.innerWidth)
    }

    useEffect(() => {
        onResize()
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize)

        return () => {
            window.removeEventListener('resize', onResize)
        }
    },[windowWidth])

    return(
        <section className={`commentsSection ${styles.commentsSection}`}>
            <div className={styles.commentsSection__header}>
                <div className={`container ${styles.commentsSection__header_container}`}>
                    <Title dark comments__title>What our <br/> customers say</Title>
                    {/*<a href="#" onClick={e => e.preventDefault()}*/}
                    {/*   className={`drop_btn ${styles.commentsSection__btn}`}>LEAVE US A REVIEW</a>*/}
                </div>
            </div>
            <div className={styles.commentsSection__body}>
                <div className={styles.comments}>
                    {windowWidth < 1100 && <Slider {...sliderSettings}>
                        {comments.map((item, index) => <Comment key={index} {...item} />)}
                    </Slider>}
                    {windowWidth >= 1100 && comments.map((item, index) => <Comment key={index} {...item} />)}

                </div>
            </div>
        </section>
    )
}

export default Comments