import av1 from '../assets/img/tora.png'
import av2 from '../assets/img/amberly.png'
import av3 from '../assets/img/leo.png'

const SET_COMMENTS = 'comments/SET_COMMENTS'

let initialState = {
  comments: [
    {
      "img": av1,
      "name": "Tora Arnborg",
      "text": "My favourite umbrella! I like how people react to it, and it definitely adds some sunshine to rainy days."
    },
    {
      "img": av2,
      "name": "Amberly Dorsey",
      "text": "Just got that umbrella, right on time for rainy summer in London… thank you for your creativity and mood!"
    },
    {
      "img": av3,
      "name": "Leonard Harman",
      "text": "It wasn’t clear that the upper side of the canopy is black when I ordered it, but I actually like it even more that way. Looks very cool and stylish."
    }
  ]

}

const commentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMMENTS:
      return {...state, comments: action.comments}
    default:
      return state
  }
}

/* Action Creators */
// export const setComments = (comments) => ({type: SET_COMMENTS, comments})
//
// /* Thunks */
// export const fetchComments = async (dispatch) => {
//     let res = await axios.get('./data/comments.json').then(res => res.data)
//     dispatch(setComments(res))
// }

export default commentsReducer
