import React, {useEffect, useState} from 'react'
import styles from './Collection.module.css'
import ArtListContainer from "./ArtList/ArtListContainer"
import PinchToZoom from 'react-pinch-and-zoom'
import {enableScroll} from "../utils/helpers"
import {CircleButton, Title} from "../common/ui"
import cn from 'classnames'
import Sliders from "./Sliders/Sliders";

let windowWidth = window.innerWidth;

const Collection = (props) => {
  let [isChangedArt, setIsChangedArt] = useState(false)
  let [showZoomedImg, setShowZoomedImg] = useState(false)
  let [isShowModal, setIsShowModal] = useState(false)
  let [currentSliderIndex, setCurrentSliderIndex] = useState(0)

  let collectionSection = null

  useEffect(() => {
    setIsChangedArt(true)
    setTimeout(() => {
      setIsChangedArt(false)
    }, 1100)
  }, [props.selectedUmbrella])

  useEffect(() => {
    props.setCollectionRef(collectionSection)
  }, [props, collectionSection])

  const hideZoom = (e) => {
    setShowZoomedImg(false);
    enableScroll()
  };

  let scrollToStores = (e) => {
    e.preventDefault()
    props.whereBuyRef.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  };


  return (
    <section id={'collection'}
             className={cn('collectionSection', styles.collection, {'container': windowWidth >= 1200})}
             ref={collectionSectionRef => (collectionSection = collectionSectionRef)}>
      <div className={cn('container', styles.collection__container, {[styles.hidden]: isShowModal})}>
        {
          windowWidth >= 1200 &&
          <Sliders
            setIsShowModal={setIsShowModal}
            isChangedArt={isChangedArt}
            isShowModal={isShowModal}
            selectedUmbrella={props.selectedUmbrella}
            setZoomedImg={props.setZoomedImg}
            setShowZoomedImg={setShowZoomedImg}
            setSlider={props.setSlider}
            currentSliderIndex={currentSliderIndex}
            setCurrentSliderIndex={setCurrentSliderIndex}
          />
        }
        <div className={styles.collection__bottom}>
          {windowWidth >= 1200 && <div className={`collection ${styles.collection__dark}`}>
            <Title dark collection__title>{props.selectedUmbrella.name}</Title>
          </div>}
          <div className={styles.collection__light}>
            <ArtListContainer
              setIsShowModal={setIsShowModal}
              isChangedArt={isChangedArt}
              isShowModal={isShowModal}
              selectedUmbrella={props.selectedUmbrella}
              setZoomedImg={props.setZoomedImg}
              setShowZoomedImg={setShowZoomedImg}
              setSlider={props.setSlider}
              currentSliderIndex={currentSliderIndex}
              setCurrentSliderIndex={setCurrentSliderIndex}
            />
          </div>
          <div className={`${styles.zoom__img_wrapper} ${showZoomedImg && styles.active} zoom_wrapper`}>
            <div className={styles.zoom_close} onTouchStart={hideZoom}>
              <span></span>
              <span></span>
            </div>
            <figure className={styles.zoom__img}>
              <PinchToZoom><img src={props.zoomedImg} alt=""/></PinchToZoom>
            </figure>
          </div>
        </div>
      </div>
      <div className={`container ${styles.collection__btn_wrapper}`}>
        <CircleButton onClick={scrollToStores}>Where to buy?</CircleButton>
      </div>
    </section>
  )
}

export default Collection
