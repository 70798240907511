import React, {useEffect, useState} from 'react'
import styles from "./Stores.module.css"

export const StoreItem = ({link, img}) => {
  let [isAnimatedElem, setIsAnimatedElem] = useState(false)
  let animatedItemRef = React.createRef()


  useEffect(() => {
    let animatedItemOffsetTop = 0
    let handleScroll = () => {
      let scrollBottom = window.scrollY + window.innerHeight - 100
      if (scrollBottom > animatedItemOffsetTop) setIsAnimatedElem(true)
    }

    window.addEventListener('scroll', handleScroll)
    animatedItemOffsetTop = animatedItemRef.getBoundingClientRect().top

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [animatedItemRef])

  return (
    <li ref={r => (animatedItemRef = r)} className={`${isAnimatedElem && styles.animate}`}><a href={link}
                                                                                              target={'_blank'}>
      <span className={styles.stores__list_span}><img src={img} alt="Logo"/></span>
    </a></li>
  )
}
