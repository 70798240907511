import React, {useState, useEffect} from 'react'
import styles from "./Comments.module.css"

const Comment = ({img, name, text}) => {
    let [isAnimatedElem, setIsAnimatedElem] = useState(false)
    let commentItemRef = React.createRef()


    useEffect(() => {
      let commentItemOffsetTop = 0

      let handleScroll = () => {
        let scrollBottom = window.scrollY + window.innerHeight
        if(scrollBottom > commentItemOffsetTop) setIsAnimatedElem(true)
      }
        window.addEventListener('scroll', handleScroll)
        commentItemOffsetTop = commentItemRef.getBoundingClientRect().top

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [commentItemRef])

    return(
        <div ref={r => (commentItemRef = r)} className={`${styles.comment} ${isAnimatedElem && styles.animate}`}>
            <div className={styles.comment__header}>
                <figure className={styles.comment__img}>
                    <img src={img} alt="Author"/>
                </figure>
                <p className={styles.comment__author}>{name}</p>
            </div>
            <div className={styles.comment__body}>
                <p>{text}</p>
            </div>
        </div>
    )
}

export default Comment
