import React, {useEffect, useState} from 'react'
import styles from './ArtList.module.css'
import {Animated} from "react-animated-css"
import ArtCoversContainer from "../ArtCovers/ArtCoversContainer"
import Sliders from "../Sliders/Sliders";
import {Title} from "../../common/ui";

const ArtList = ({
                   currentUmbrellas, setCurrentUmbrellas, setSelectedUmbrella,
                   setZoomedImg, nav2, nav1, ...props
                 }) => {
  let [classicActive, setClassicActive] = useState(true)
  let [compactActive, setCompactActive] = useState(false)

  useEffect(() => {
    setSelectedUmbrella(currentUmbrellas[0])
  }, [currentUmbrellas, setSelectedUmbrella])

  const toggleActiveClassic = () => {
    setClassicActive(!classicActive)
    setCompactActive(false)
    setCurrentUmbrellas('classic')
    goFirstSlide()
    setTimeout(() => {
      setZoomedImg(0)
    }, 100)
  }

  const toggleActiveCompact = () => {
    setCompactActive(!compactActive)
    setClassicActive(false)
    setCurrentUmbrellas('compact')
    goFirstSlide()
    setTimeout(() => {
      setZoomedImg(0)
    }, 100)
  }

  let goFirstSlide = () => {
    nav2.slickGoTo(0)
    nav1.slickGoTo(0)
    props.setCurrentSliderIndex(0)
  }

  return (
    <div className={styles.artList__wrapper}>
      {window.innerWidth >= 1200 &&
      <ArtCoversContainer
        umbrellas={currentUmbrellas}
        title='Choose art'
        isChangedArt={props.isChangedArt}
        setCurrentSliderIndex={props.setCurrentSliderIndex}
      />}
      {window.innerWidth < 1200 && <div>
        <div className={styles.collection__options}>
          <div className={`${styles.collection__option} ${classicActive && styles.active}`}
               onClick={toggleActiveClassic}><span>Classic</span></div>
          <div className={`${styles.collection__option} ${compactActive && styles.active}`}
               onClick={toggleActiveCompact}><span>Compact</span></div>
        </div>
        <Sliders
          setIsShowModal={props.setIsShowModal}
          isChangedArt={props.isChangedArt}
          isShowModal={props.isShowModal}
          selectedUmbrella={props.selectedUmbrella}
          setZoomedImg={setZoomedImg}
          setSlider={props.setSlider}
          setShowZoomedImg={props.setShowZoomedImg}
          currentSliderIndex={props.currentSliderIndex}
          setCurrentSliderIndex={props.setCurrentSliderIndex}
        />
        <Title dark collection__title>{props.selectedUmbrella.name}</Title>

        <p className={`${styles.artList__title} ${styles.collection__options_title}`}>UMBRELLA ART</p>

        <ArtCoversContainer
          umbrellas={currentUmbrellas}
          title='Choose art'
          isChangedArt={props.isChangedArt}
          setCurrentUmbrellas={setCurrentUmbrellas}
          setCurrentSliderIndex={props.setCurrentSliderIndex}
        />
      </div>}
      {window.innerWidth >= 1200 && <div className={styles.collection__options_wrapper}>
        <p className={`${styles.artList__title} ${styles.collection__options_title}`}>UMBRELLA MODEL</p>
        <div className={styles.collection__options}>
          <div className={`${styles.collection__option} ${classicActive && styles.active}`}
               onClick={toggleActiveClassic}><span>Classic</span></div>
          <div className={`${styles.collection__option} ${compactActive && styles.active}`}
               onClick={toggleActiveCompact}><span>Compact</span></div>
        </div>
      </div>}
      <div className={styles.artList__specifications_wrapper}>
        <p className={styles.artList__title}>SPECIFICATIONS</p>
        <ul className={`${styles.artList__specifications} ${props.isChangedArt && styles.lock}`}>
          {props.specifications.map(specification =>
            <Animated key={specification.id} animationIn="bounceIn" animationOut="fadeOut" isVisible={true}>
              <li className={`${specification.id === 0 ? styles.first : ''}`}><span>{specification.text}</span></li>
            </Animated>)}
        </ul>
      </div>
    </div>
  )
}

export default ArtList
