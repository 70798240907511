import dblayer from '../assets/img/quality/dlayer.svg'
import ribs from '../assets/img/quality/ribs.svg'
import autoOpen from '../assets/img/quality/auto-open.svg'
import cup from '../assets/img/quality/cup.svg'
import handle from '../assets/img/quality/handle.svg'

let initialState = {
    qualityItems: [
        {
            id: 0,
            img: dblayer,
            title: 'Double layer pongee fabric',
            text: 'Woven from raw silk, Pongee offers high resistance to tearing and abrasion. Its unique texture makes it highly resistant to soaking and allows it to dry quickly.'
        },
        {
            id: 1,
            img: ribs,
            title: '8 steel and fibreglass ribs',
            text: 'The ribs of the umbrella are made to last and painted black for that slick stylish look that goes well with the art, without taking anything away from it.'
        },
        {
            id: 3,
            img: autoOpen,
            title: 'Auto-open mechanism',
            text: 'Compact umbrellas also have an auto-close mechanism that is very satisfying to operate.'
        },
        {
            id: 4,
            img: cup,
            title: 'Nickel-aluminium tip cup',
            text: 'A metal ring above the handle that acts as a lip for canopy rib tips to rest when the umbrella is closed.'
        },
        {
            id: 5,
            img: handle,
            title: 'Wooden crook handle',
            text: 'Elegant and comfortable handles made of wood, round ones on compact umbrellas and crook ones on classic umbrellas.'
        },
    ]
}

let qualityReducer = (state = initialState, action) => state

export default qualityReducer