import React, {useEffect} from 'react'
import {connect} from "react-redux"
import Comments from "./Comments"
// import {fetchComments} from "../../redux/comments-reducer"

const CommentsContainer = props => {

    useEffect(() => {
        // fetchComments()
    }, [])

    return <Comments {...props} />
}

const mapStateToProps = (state) => ({
    comments: state.comments.comments
})

export default connect(mapStateToProps, null
    // {fetchComments}
    )(CommentsContainer)