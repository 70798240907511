import React from "react"
import styles from "./Socials.module.css"
import Social from "./Social"

const Socials = ({socials}) => {
    let socialsItems = socials.socials

    return(
        <ul className={styles.socials}>
            {socialsItems.map(social => <Social key={social.id} {...social} /> )}
        </ul>
    )
}

export default Socials