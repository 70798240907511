let root = document.getElementsByTagName('html')[0]

const preventDefault = (e) => {
  e.preventDefault()
  return false
}


export const disableScroll = (level) => {
  if (level === 'soft') {
    window.addEventListener('wheel', preventDefault, {passive: false})
    window.addEventListener('scroll', preventDefault, {passive: false})
  } else {
    window.addEventListener('wheel', preventDefault, {passive: false})
    window.addEventListener('scroll', preventDefault, {passive: false})
    window.addEventListener('touchmove', preventDefault, {passive: false})
    window.addEventListener('touchstart', preventDefault, {passive: false})
  }
  document.body.classList.add('no-scroll')
  root.classList.add('no-scroll')
}


export const enableScroll = (level) => {
  if (level === 'soft') {
    window.removeEventListener('wheel', preventDefault, {passive: false})
    window.removeEventListener('scroll', preventDefault, {passive: false})
  } else {
    window.removeEventListener('wheel', preventDefault, {passive: false})
    window.removeEventListener('scroll', preventDefault, {passive: false})
    window.removeEventListener('touchmove', preventDefault, {passive: false})
    window.removeEventListener('touchstart', preventDefault, {passive: false})
  }
  document.body.classList.remove('no-scroll')
  root.classList.remove('no-scroll')
}

/* COOKIES */
export const setCookie = (cname, cvalue, exdays) => {
  var d = new Date()
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
  var expires = "expires=" + d.toUTCString()
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
}

export const getCookie = (cname) => {
  var name = cname + "="
  var ca = document.cookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ""
}

export const goTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  })
}
