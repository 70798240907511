import React, {useState, useEffect} from 'react'
import styles from "./PremiumQuality.module.css"

const QualityItem = ({img, title, text}) => {
    let [windowWidth, setWindowWidth] = useState(null)
    let [isAnimatedElem, setIsAnimatedElem] = useState(false)
    let qualityItemRef = React.createRef()

    let onResize = () => {
        setWindowWidth(window.innerWidth)
    }


    useEffect(() => {

      let QualityItemOffsetTop = 0

      let handleScroll = () => {
        let scrollBottom = window.scrollY + window.innerHeight - 100
        if(scrollBottom > QualityItemOffsetTop) setIsAnimatedElem(true)
      }

        onResize()
        window.addEventListener('scroll', handleScroll)
        QualityItemOffsetTop = qualityItemRef.getBoundingClientRect().top

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [qualityItemRef])

    useEffect(() => {
        window.addEventListener('resize', onResize)

        return () => {
            window.removeEventListener('resize', onResize)
        }
    },[windowWidth])

    return(
        <div ref={r => (qualityItemRef = r)}
             className={`${styles.premiumQuality__item} ${isAnimatedElem && styles.animate}`}>
            <div className={styles.premiumQuality__item_header}>
                <figure className={`${styles.premiumQuality__item_img} ${img === null && styles.empty}`}>
                    {img && <img src={img} alt="Quality Item"/>}
                </figure>
                {windowWidth < 992 && <h3>{title}</h3>}
            </div>
            <div className={styles.premiumQuality__item_info}>
                {windowWidth >= 992 && <h3>{title}</h3>}
                <p>{text}</p>
            </div>
        </div>
    )
}

export default QualityItem
