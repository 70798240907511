import React from 'react'
import {ArtCovers} from "./ArtCovers"
import {connect} from "react-redux"
import {setSelectedUmbrella, setZoomedImg} from "../../../redux/umbrella-reducer"

const ArtCoverContainer = (props) => {
  let goFirstSlide = () => {
    props.nav2.slickGoTo(0)
    props.nav1.slickGoTo(0)
    props.setCurrentSliderIndex(0)

  }

  let setArt = (item) => {
    props.setSelectedUmbrella(item)
    props.setZoomedImg(0)
      goFirstSlide()
  }

  return <ArtCovers setArt={setArt} {...props} />
}

const mapStateToProps = (state) => ({
  // umbrellas: state.umbrella.currentModel.umbrellas,
  selectedUmbrella: state.umbrella.selectedUmbrella,
  nav1: state.umbrella.sliders.nav1,
  nav2: state.umbrella.sliders.nav2,
})

export default connect(mapStateToProps, {setSelectedUmbrella, setZoomedImg})(ArtCoverContainer)
