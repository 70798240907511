import React from 'react'
import styles from './Footer.module.css'
import SocialsContainer from "../common/Socials/SocialsContainer"
import footerImg from '../../assets/img/footer-img.svg'


const Footer = () => {
  return (
    <footer id={"footer"} className={styles.footer}>
      <div className={styles.footerImg}>
        <img src={footerImg} alt=""/>
      </div>
      <div className={styles.footerTop}>
        <div className={'container'}>
          <p className={styles.footerTop__description}>
            If you want to make a bulk purchase for retail, or for any other reason please contact us:
          </p>
          <div className={styles.footer__feedback}>
            <a href="tel:46722434144"> +(46) 722-43-41-44</a>
            <a href="mailto:info@zontjkdesign.se" className={styles.footer__email}>info@zontjkdesign.se</a>
          </div>
        </div>
      </div>
      <div className={styles.footerBottom}>
        <div className={'container'}>
          <div className={styles.footerBottom__content}>

          <div className={styles.footer__copyright}>
            <p>&copy; ZontjkDesign sweden</p>
            <span>Karlsviksgatan 5, 11241 Stockholm</span>
          </div>
          <div className={styles.footer__socials}>
            <SocialsContainer/>
          </div>
        </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
