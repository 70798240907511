import React, {useState, useEffect} from 'react'
import {connect} from "react-redux"
import Header from "./Header"
import {setOpenedBurger} from "../../redux/global-reducer"

const HeaderContainer = ({setOpenedBurger, ...props}) => {
    let [isOnTop, setIsOnTop] = useState(true)

    let handleScroll = () => {
            if(window.scrollY > 100) {
                setOpenedBurger(false)
                setIsOnTop(false)
            } else {
                setOpenedBurger(true)
                setIsOnTop(true)
            }
    }
    useEffect(() => {
        if(window.innerWidth < 1200) {
            setOpenedBurger(false)
        } else {
            window.addEventListener('scroll',handleScroll)
        }

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    },[])

    return <Header isOnTop={isOnTop} setOpenedBurger={setOpenedBurger} {...props} />
}

const mapStateToProps = (state) => ({
    openedBurger: state.global.openedBurger
})

export default connect(mapStateToProps, {setOpenedBurger})(HeaderContainer)
