import Collection from "./Collection"
import {connect} from "react-redux"
import {
    setSlider,
    setZoomedImg
} from "../../redux/umbrella-reducer"
import {setCollectionRef} from "../../redux/refs-reducer"

const mapStateToProps = (state) => ({
    zoomedImg: state.umbrella.zoomedImg,
    selectedUmbrella: state.umbrella.selectedUmbrella,
    whereBuyRef: state.refs.whereBuyRef
})

export default connect(mapStateToProps,
    {setZoomedImg, setSlider, setCollectionRef})(Collection)
