import React, {useState, useEffect} from "react"
import styles from "./MobileMenu.module.css"
import SocialsContainer from "../../common/Socials/SocialsContainer"
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Scrollspy from 'react-scrollspy'
import {enableScroll} from "../../utils/helpers"
import MobileLogo from './MobileLogo'

let MenuItems = [
    {
        href: '#collection',
        text: 'Umbrella collection'
    },
    {
        href: '#story',
        text: 'Story behind art'
    },
    {
        href: '#wheretobuy',
        text: 'Where to buy?'
    },
    {
        href: '#footer',
        text: 'Contact'
    },
]

const MobileMenu = (props) => {
    let [activeIndex, setActiveIndex] = useState(null)
    let [isScrolledFromTop, setIsScrolledFromTop] = useState(false)
    let [isMenuScrolled, setIsMenuScrolled] = useState(false)
    let [scrollY, setScrollY] = useState(0)

    const closeModal = () => {
        props.setOpenedBurger(false)
        enableScroll()
    }

    const handleMenuClick = (index) => {
        setActiveIndex(index)
    }

    let handleScroll = () => {
        window.scrollY >= 100 ? setIsScrolledFromTop(true) : setIsScrolledFromTop(false)
        window.scrollY >= 120 ? setIsMenuScrolled(true) : setIsMenuScrolled(false)
        setScrollY(window.scrollY)
    }

    let goTop= () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return(
        <div className={`${styles.mobile_menu} ${props.isOpened && styles.active} ${isMenuScrolled && styles.scrolled} ${props.color && styles.color} `}>
            <div className={`container ${styles.mobile_menu__container} `}>
                <MobileLogo show={isScrolledFromTop} onClick={goTop} />
                <Scrollspy items={ ['collection', 'story', 'wheretobuy', 'footer'] } currentClassName={`${scrollY > 100 && styles.active}`}
                           className={`${styles.mobile_menu__main} ${props.isOnTop && styles.onTop}`} offset={0}>
                {MenuItems.map((btn, index) =>
                    <li key={index} className={`${(activeIndex === index && props.changeBurgerColor) && styles.active_link}`}
                        onClick={() => {handleMenuClick(index)}}>
                        <AnchorLink href={btn.href} onClick={closeModal}><span>{btn.text}</span></AnchorLink>
                    </li>)}
                </Scrollspy>
                {/*<ul className={styles.mobile_menu__secondary}>*/}
                {/*    <li><a href="#">Become a reseller</a></li>*/}
                {/*</ul>*/}
                <div className={styles.mobile_menu__socials}>
                    <SocialsContainer />
                </div>
            </div>
            {window.innerWidth < 1200 && <button className={`logo ${props.isHandleLogoUp && 'up'}`} onTouchStart={props.handleLogoUp}
                                                 onClick={props.handleLogoUp}>
                <span></span>
            </button>}
        </div>
    )
}

export default MobileMenu
