import React from 'react'
import styles from './PremiumQuality.module.css'
import QualityItem from "./QualityItem"
import {Title} from "../common/ui"

const PremiumQuality = props => {
    return(
        <section className={styles.premiumQuality}>
           <div className={'container'}>
               <Title dark quality__title>Premium quality <br/> umbrellas</Title>
               <div className={styles.premiumQuality__wrapper}>
                   <div className={styles.premiumQuality__items}>
                       {props.qualityItems.map(item => <QualityItem key={item.id} {...item} />)}
                   </div>
               </div>
           </div>
        </section>
    )
}

export default PremiumQuality