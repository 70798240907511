import React, {Component} from 'react'
import HomeContainer from "../Home/HomeContainer"
import CollectionContainer from "../Collection/CollectionContainer"
import StoryContainer from "../Story/StoryContainer"
import PremiumQualityContainer from "../PremiumQuality/PremiumQualityContainer"
import CommentsContainer from "../Comments/CommentsContainer"
import styles from "./HomePage.module.css"
import {ParallaxProvider} from "react-scroll-parallax"
import StoresContainer from "../Stores/StoresContainer";

class HomePage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isComponentMounted: false
        }
    }

    componentDidMount() {
        setTimeout(() => {this.setState({isComponentMounted: true})}, 100)
    }

    render() {
        return (
            <ParallaxProvider>
                <div className={`homePage ${styles.homePage} ${this.state.isComponentMounted ? `mounted ${styles.pageIsLoaded}` : 'unmounted'} ${this.props.isBackToHome && 'isBackToHome'}`}>
                    <HomeContainer isBackToHome={this.props.isBackToHome} setIsBackToHome={this.props.setIsBackToHome}/>
                    <CollectionContainer/>
                    <div className={styles.homePage__comments}>
                        <StoryContainer/>
                        <PremiumQualityContainer/>
                        <CommentsContainer/>
                    </div>
                </div>
              <StoresContainer />
            </ParallaxProvider>
        )
    }
}

export default HomePage
