import React, {useEffect} from 'react'
import styles from './Stores.module.css'
import {Element} from 'react-scroll'
import {StoreItem} from "./StoreItem"
import {Title} from "../common/ui"

const Stores = (props) => {
    let storesSection = null

    useEffect(() => {
        props.setWherebuyRef(storesSection)
    }, [props, storesSection])

    return(
        <section id={'wheretobuy'} className={styles.stores} ref={storesSectionRef => (storesSection = storesSectionRef)}>
            <Element name="myScrollToElement">
                <div className={'container'}>
                    <div className={styles.stores__header}>
                        <span>And so can you – for yourself,  or as a perfect gift for someone close.</span>
                        <Title dark stores__title>You can get your unique art <br/>umbrella right now here:</Title>
                    </div>
                        <ul className={styles.stores__list}>
                            {props.stores.map((store, index) => <StoreItem key={index} {...store} />)}
                                {/*<li className={styles.stores__list_seller}>*/}
                                {/*    <a href="https://www.google.com" target={'_blank'}>*/}
                                {/*        <span className={`${styles.stores__list_circle}`}></span>*/}
                                {/*        <span className={`${styles.stores__list_span}`}></span>*/}
                                {/*        Become <br/> a reseller*/}
                                {/*    </a>*/}
                                {/*</li>*/}
                        </ul>
                </div>
            </Element>
        </section>
    )
}

export default Stores
