import {connect} from "react-redux"
import Home from "./Home"
import {setSlider, setZoomedImg} from "../../redux/umbrella-reducer"
import {setOpenedBurger} from "../../redux/global-reducer"

const mapStateToProps = (state) => ({
    imgs: state.umbrella.selectedUmbrella.images,
    nav1: state.umbrella.sliders.nav1,
    collectionRef: state.refs.collectionRef,
    openedBurger: state.global.openedBurger
})

export default connect(mapStateToProps, {setSlider, setZoomedImg, setOpenedBurger})(Home)
