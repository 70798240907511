import styled from 'styled-components'
import {device} from '../common/ui'

export const Logo = styled.figure`
    position: absolute;
    top: 0px;
    left: 0;
    padding: 20px;
    width: 130px;
    overflow: hidden;
    z-index: 5;
    
    > img {
        width: 100%;
    }
    
    @media ${device.tablet} {
        left: -10px;
    }
    
    @media ${device.laptop} {
        left: 0;
    }
    
    @media ${device.laptopM} {
        top: -5px;
        padding: 30px;
        width: 150px;
    }
`
