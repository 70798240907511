import React from 'react'
import styled, {css} from 'styled-components'

/* MEDIA QUERIES - DEVICES */
const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopM: '1200px',
    laptopL: '1440px',
    desktop: '2560px'
}

export const device = {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopM: `(min-width: ${size.laptopM})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktop})`
}

/* END MEDIA QUERIES - DEVICES */

/* BUTTON */
const Circle = styled.span`
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    
    ::before,
    ::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 50%;
        height: 100%;
        border-radius: 0 100% 100% 0 / 0 50% 50% 0;
        background: rgba(82,114,255,1);
        transform-origin: center left;
        transition: all .45s;
        z-index: -1;
    }
`

const Button = styled.a`
    position: relative;
    display: block;
    padding: 10px 35px;
    font-family: 'Tussilago', sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.04px;
    text-decoration: none;
    text-transform: uppercase;
    width: max-content;
    color: rgba(82,114,255,1);
    cursor: pointer;
    transition: all .45s;
    
    :hover {
        padding-left: 20px;
    }
    
    :hover ${Circle}::before {
        transform: rotate(360deg);
    }
    
    @media screen and (min-width: 1400px) {
        font-size: 14px;
        line-height: 15px;
    }
`

export const CircleButton = props => <Button {...props}><Circle></Circle>{props.children}</Button>
/* END BUTTON */

export const Title = styled.h2`
    position: relative;
    font-family: 'Tussilago', sans-serif;
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
    text-align: left;
    color: rgba(227,227,227,1);
    
    @media ${device.laptop} {
        font-size: 26px;
        line-height: 26px;
    }
    
    @media ${device.laptopL} {
        font-size: 36px;
        line-height: 42px;
    }
    
    ::first-letter {
        text-transform: uppercase;
    }
    
    ${props => props.dark && css`
        color: rgba(35,35,35,1);
    `}
    
    ${props => props.collection__title && css`
      margin-bottom: 0;
      
      @media ${device.laptopM} {
         margin-bottom: 30px;
      }
      
      @media ${device.laptopL} {
         margin-bottom: 50px;
      }
    `}
    
    ${props => props.story__title && css`
        margin-bottom: 17px;
        
        @media ${device.laptopL} {
            margin-bottom: 45px;
        }
    `}
    
    ${props => props.quality__title && css`
        flex: 1;
        line-height: 32px;
        text-align: left;
        
        @media ${device.laptopM} {
            position: relative;
            
            ::before {
                content: '';
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                display: block;
                width: 50%;
                height: 1px;
                background-color: #5272FF;
            }
        }
        
        @media ${device.laptopL} {
            margin-bottom: 70px;
            line-height: 38px;
        }
    `}
    
    ${props => props.comments__title && css`
        margin-bottom: 20px;
        line-height: 32px;
        text-align: left;
        max-width: none;
        
        @media ${device.laptopL} {
            margin-bottom: 0;
            line-height: 38px;
        }
    `}
    
    ${props => props.stores__title && css`
        position: relative;
        margin: 40px 0 20px;
        text-align: left;
        max-width: none;
        
        ::before {
            content: '';
            position: absolute;
            left: -50px;
            top: -40px;
            display: block;
            width: 150px;
            height: 150px;
            background-image: url('./img/umbrella-quality.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }
        
        @media ${device.laptopM} {
            margin-top: 0;
            
            ::before {
                left: -110px;
                top: 50%;
                transform: translateY(-50%);
                display: block;
                width: 300px;
                height: 250px;
            }
        }
    `}
`
