import amazon from '../assets/img/amazon.png'
import shop_online from '../assets/img/shop_online.svg'
// import cdon from '../assets/img/cdon.png'
// import ebay from '../assets/img/ebay.png'
import tipser from '../assets/img/tipser.svg'
import etsy from '../assets/img/etsy.png'
import mymall from '../assets/img/mymall.png'

const SET_STORES_REF = 'SET_STORES_REF'

let initialState = {
    stores: [
        {
            img: amazon,
            link: 'https://www.amazon.se/s?k=zontjk&ref=nb_sb_noss'
        },
        {
            img: shop_online,
            link: 'https://www.zontjkdesign.se/'
        },
        {
            img: tipser,
            link: 'https://www.tipser.com/Zontjk-design'
        },
        /* {
            img: cdon,
            link: 'https://cdon.se/search?q=zontjkdesign'
        }, */
        {
            img: mymall,
            link: 'https://mymall.se/catalogsearch/result/?q=zontjk'
        },
        {
            img: etsy,
            link: 'https://www.etsy.com/se-en/shop/Zontjkdesign?ref=search_shop_redirect'
        },
    ],
    storesRef: null
}

const storesReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_STORES_REF:
            return {
                ...state,
                storesRef: action.storesRef
            }
        default:
            return state
    }
}

export const setStoresRef = (storesRef) => ({type:SET_STORES_REF, storesRef})

export default storesReducer
