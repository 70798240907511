import {createStore, combineReducers, compose, applyMiddleware} from 'redux'
import socialsReducer from './socials-reducer'
import umbrellaReducer from './umbrella-reducer'
import commentsReducer from "./comments-reducer"
import storesReducer from "./stores-reducer"
import refReducer from "./refs-reducer"
import globalReducer from "./global-reducer"
import qualityReducer from "./quality-reducer"
import thunkMiddleware from 'redux-thunk'

let reducers = combineReducers({
    socials: socialsReducer,
    umbrella: umbrellaReducer,
    comments: commentsReducer,
    stores: storesReducer,
    refs: refReducer,
    global: globalReducer,
    quality: qualityReducer
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunkMiddleware)))

window.store = store

export default store
